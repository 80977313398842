import {
  REPORT_CASES,
  REPORT_PROCEDURES,
  REPORT_FETCHED_DATE,
} from '../constants';

const initialState = {
  cases: [],
  lastFetchedDate: null,
  procedures: {},
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case REPORT_CASES:
      return {
        ...state,
        cases: action.cases,
      };
    case REPORT_PROCEDURES:
      return {
        ...state,
        procedures: action.procedures,
      };
    case REPORT_FETCHED_DATE:
      return {
        ...state,
        lastFetchedDate: action.lastFetchedDate,
      };
    default:
      return state;
  }
}
