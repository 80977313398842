import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { stack as StackMenu } from 'react-burger-menu';
import withStyles from 'isomorphic-style-loader/withStyles';

import Link from 'components/Link';

const menuSettings = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    right: '36px',
    top: '36px',
    zIndex: 1051,
  },
  bmBurgerBars: {
    background: 'white',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenu: {
    background: '#373a47',
    padding: '2.5em 1.0em 0',
    fontSize: '1.15em',
  },
  bmMenuWrap: {
    zIndex: 1052,
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
};

class Menu extends React.Component {
  static propTypes = {
    links: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  constructor() {
    super();
    this.state = {
      menuOpen: false,
    };
    _.bindAll(this, 'handleStateChange', 'closeMenu');
  }

  handleStateChange(state) {
    setTimeout(() => {
      // need to delay to preserve the animation of the bars
      this.setState({ menuOpen: state.isOpen });
    }, 1000);
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  render() {
    return (
      <StackMenu
        right
        styles={menuSettings}
        isOpen={this.state.menuOpen}
        onStateChange={state => this.handleStateChange(state)}
      >
        {_.map(this.props.links, (link, i) => {
          if (_.has(link, 'raw')) {
            return link.raw;
          }
          if (_.startsWith(link.href, 'http')) {
            return (
              <a key={`menu_${i}`} href={link.href}>
                {link.name}
              </a>
            );
          }
          return (
            <Link key={`menu_${i}`} onClick={this.closeMenu} to={link.href}>
              {link.name}
            </Link>
          );
        })}
      </StackMenu>
    );
  }
}

const mapStatetoProps = state => ({
  coordinator: state.user.coordinator,
  ...state.auth,
});

export default connect(mapStatetoProps, null)(withStyles()(Menu));
