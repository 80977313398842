import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
import bootstrap from 'bootstrap/dist/css/bootstrap.css';
import cn from 'classnames';

import Menu from 'components/Menu';
import ContactLink from 'components/ContactLink';

import main from '../../styles/global/main.scss'; // eslint-disable-line
import s from './Layout.scss';
import Header from '../Header';
import Footer from '../Footer';

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    userAgent: PropTypes.string.isRequired,
    isAuth: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isGlobalAdmin: PropTypes.bool.isRequired,
    coordinator: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    coordinator: {},
  };

  // componentDidCatch(error) {
  //   // eslint-disable-line class-methods-use-this
  //   TraceKit.report(error);
  // }

  render() {
    let links = [
      {
        href: '/',
        name: 'Home',
      },
    ];

    let headerLinks = [];
    let addBreak = false;
    if (this.props.isAdmin) {
      addBreak = true;
      headerLinks = _.concat(headerLinks, {
        href: '/program',
        name: 'Program',
      });
      headerLinks = _.concat(headerLinks, {
        href: '/program/enrollees',
        name: 'Enrollees',
      });
    }
    if (addBreak) {
      links = _.concat(links, headerLinks);
      links = _.concat(links, { raw: <hr key={_.uniqueId('menu_hr_')} /> });
    }

    if (this.props.isAuth) {
      const logout = {
        href: '/logout',
        name: 'Log Out',
      };
      links = _.concat(links, [
        {
          href: '/changePassword',
          name: 'Change Password',
        },
        logout,
      ]);
      headerLinks = _.concat(headerLinks, logout);
    } else {
      headerLinks = _.concat(headerLinks, [
        {
          href: '/login',
          name: 'Log in',
        },
        {
          href: '/register',
          name: 'Register',
        },
      ]);
      links = _.concat(links, headerLinks);
    }

    links = _.concat(links, { raw: <hr key={_.uniqueId('menu_hr_')} /> });

    links = _.concat(links, [
      {
        href: '/videos',
        name: 'Instructional Videos',
      },
      {
        href: '/QRCodes/iOS',
        name: 'Download iOS App',
      },
      {
        href: '/QRCodes/android',
        name: 'Download Android App',
      },
      {
        raw: <ContactLink key="menu_contact_link">Contact Us</ContactLink>,
      },
    ]);

    return (
      <div className={cn([s.mainWrapper, 'jumbotron'])}>
        <Header links={headerLinks} />
        <Menu {...{ links }} />
        {this.props.children}
        <Footer />
      </div>
    );
  }
}
const mapStatetoProps = state => ({
  coordinator: state.user.coordinator,
  ...state.auth,
});

export default connect(
  mapStatetoProps,
  null,
)(withStyles(bootstrap, main, s)(Layout));
