import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Nav, Navbar, NavbarBrand, NavItem } from 'reactstrap';
import _ from 'lodash';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Header.scss';
import Link from '../Link';

const wrapWithNavItem = component => (
  <NavItem className="nav-link" key={_.uniqueId('navItem_')}>
    {component}
  </NavItem>
);

class Header extends React.Component {
  static propTypes = {
    links: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  render() {
    return (
      <Navbar expand="md" className={cn(['navbar', s.header])}>
        <NavbarBrand tag="div">
          <Link to="/" className="d-flex align-items-center">
            <span className={s.headerText}>BeFitMe</span>
          </Link>
        </NavbarBrand>
        <Collapse navbar>
          <Nav navbar className={cn(['ml-auto', s.navItems])}>
            {_.map(this.props.links, (link, i) => {
              if (_.has(link, 'raw')) {
                return wrapWithNavItem(link.raw);
              }
              if (_.startsWith(link.href, 'http')) {
                return wrapWithNavItem(
                  <a key={`header_link_${i}`} href={link.href}>
                    {link.name}
                  </a>,
                );
              }
              return wrapWithNavItem(
                <Link
                  key={`header_link_${i}`}
                  onClick={this.closeMenu}
                  to={link.href}
                >
                  {link.name}
                </Link>,
              );
            })}
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

export default withStyles(s)(Header);
