// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3dP95{background:#333;color:#fff;width:100%;position:absolute;bottom:0;min-height:130px}._26pKB,._3dP95{text-align:center}._26pKB{margin:0 auto;padding:20px 15px;max-width:1000px}._3n7Lv{color:hsla(0,0%,100%,.3)}.NoJN3{padding:2px 5px;font-size:1em}.NoJN3,.NoJN3:active,.NoJN3:visited{color:#fff;text-decoration:none}.NoJN3:hover{color:hsla(0,0%,100%,.6)}", ""]);
// Exports
exports.locals = {
	"root": "_3dP95",
	"container": "_26pKB",
	"spacer": "_3n7Lv",
	"link": "NoJN3"
};
module.exports = exports;
