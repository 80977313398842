// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._13MfM{padding-top:15px;text-align:center}._3kj_G,._3kj_G:active,._3kj_G:hover,._3kj_G:visited{text-decoration:none}._3kj_G:hover{text-decoration:underline}", ""]);
// Exports
exports.locals = {
	"container": "_13MfM",
	"link": "_3kj_G"
};
module.exports = exports;
