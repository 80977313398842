import Router from 'universal-router';
import routes from './routes';

export default new Router(routes, {
  resolveRoute(context, params) {
    if (typeof context.route.loadChildren === 'function') {
      return context.route.loadChildren().then(action => {
        if (context.route.children) {
          context.route.children.push(action.default);
        } else {
          throw new Error('Route must have children');
        }
        return context.next();
      });
    }
    if (typeof context.route.load === 'function') {
      return context.route.load().then(action => {
        if (typeof action.default === 'function') {
          return action.default(context, params);
        }
        return action.default.action(context, params);
      });
    }
    if (typeof context.route.action === 'function') {
      return context.route.action(context, params);
    }
    return null;
  },
});
