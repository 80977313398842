import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Footer.scss';
import Feedback from './Feedback';
import Link from '../Link';

class Footer extends React.Component {
  render() {
    return (
      <div className={s.root}>
        <Feedback />
        <Container fluid className={s.container}>
          <Row>
            <Col xs={12}>
              <Link className={s.link} to="/">
                Home
              </Link>
              <span className={s.spacer}>·</span>
              <a className={s.link} href="/learnMore">
                Learn More
              </a>
              <span className={s.spacer}>·</span>
              <a className={s.link} href="/privacy">
                Terms of Service & Privacy
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withStyles(s)(Footer);
