import React from 'react';
import { Container, Row } from 'reactstrap';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import ContactLink from '../../ContactLink';
import s from './Feedback.scss';

class Feedback extends React.Component {
  render() {
    return (
      <Container fluid>
        <Row className={cn([s.container, 'justify-content-center'])}>
          <ContactLink className={s.link}>
            Ask a question &nbsp;&nbsp; | &nbsp;&nbsp; Report an issue
          </ContactLink>
        </Row>
      </Container>
    );
  }
}

export default withStyles(s)(Feedback);
