import React from 'react';
import PropTypes from 'prop-types';
import { Provider as ReduxProvider } from 'react-redux';

const ContextType = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: PropTypes.func.isRequired,
  // Universal HTTP client
  fetch: PropTypes.func.isRequired,
  ...ReduxProvider.childContextTypes,
  pathname: PropTypes.string.isRequired,
  query: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  store: PropTypes.any,
};

class App extends React.PureComponent {
  static propTypes = {
    context: PropTypes.shape(ContextType).isRequired,
    children: PropTypes.element.isRequired,
  };

  static childContextTypes = ContextType;

  getChildContext() {
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.context;
  }

  render() {
    // NOTE: If you need to add or modify header, footer etc. of the app,
    // please do that inside the Layout component.
    // eslint-disable-next-line react/destructuring-assignment
    return React.Children.only(this.props.children);
  }
}

export default App;
