import 'whatwg-fetch';
import React from 'react';
import Parse from 'parse';
import request from 'superagent';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import deepForceUpdate from 'react-deep-force-update';
import queryString from 'query-string';
import localForage from 'localforage';
import _ from 'lodash';
import { createPath } from 'history';

import configureStore from 'store/configureStore';
import {
  loginUser,
  logoutUser,
  setCoordinator,
  setUserInstitution,
  setUserSpecialty,
} from 'actions/user';
import { SuccessAlert } from 'helpers/alert';
import { setAuthProps } from 'actions/auth';
import App from './components/App';
import Layout from './components/Layout';
import createFetch from './createFetch';
import history from './core/history';
import { updateMeta } from './DOMUtils';
import router from './router';

// initialize Parse
Parse.initialize(window.App.PARSE_APP_ID, window.App.PARSE_CLIENT_KEY);
Parse.serverURL = window.App.PARSE_SERVER_URL;

const { userAgent } = window.App;
let workingSurvey = { surveyVersion: -1 };
const workingSurveyString = localStorage.getItem('workingSurvey');
if (workingSurveyString && workingSurveyString !== 'undefined') {
  workingSurvey = JSON.parse(workingSurveyString);
}
const existingState = {
  ...window.App.state,
  workingSurvey,
};
const store = configureStore(existingState, { history });

store.subscribe(() => {
  const newSurvey = store.getState().workingSurvey;
  if (
    workingSurvey.surveyVersion < newSurvey.surveyVersion ||
    (newSurvey.surveyVersion === -1 && workingSurvey.surveyVersion !== -1)
  ) {
    workingSurvey = newSurvey;
    localStorage.setItem('workingSurvey', JSON.stringify(newSurvey));
  }
});

/* eslint-disable no-underscore-dangle */

if (process.env.NODE_ENV !== 'production') {
  window.Parse = Parse;
  window.request = request;
  window.store = store;
  window.localForage = localForage;
  window._ = _;
  window.SuccessAlert = SuccessAlert;
  window.FindReact = dom => {
    /* eslint-disable no-underscore-dangle  */
    /* eslint-disable no-restricted-syntax */
    for (const key in dom) {
      // eslint-disable-line no-restricted-syntax
      if (key.startsWith('__reactInternalInstance$')) {
        const compInternals = dom[key]._currentElement;
        const compWrapper = compInternals._owner;
        const comp = compWrapper._instance;
        return comp;
      }
    }
    return null;
  };
}

// Enables critical path CSS rendering
// https://github.com/kriasoft/isomorphic-style-loader
const insertCss = (...styles) => {
  // eslint-disable-next-line no-underscore-dangle
  const removeCss = styles.map(x => x._insertCss());
  return () => {
    removeCss.forEach(f => f());
  };
};

// Global (context) variables that can be easily accessed from any React component
// https://facebook.github.io/react/docs/context.html
const context = {
  // Universal HTTP client
  fetch: createFetch(fetch, {
    baseUrl: window.App.apiUrl,
  }),
  insertCss,
  store,
};

const container = document.getElementById('app');
let currentLocation = history.location;
let appInstance;

const scrollPositionsHistory = {};

async function saveSessionToServer(user) {
  return new Promise(resolve => {
    request
      .post('/api/session')
      .send({ method: 'add', sessionToken: user.getSessionToken() })
      .end(() => resolve());
  });
}

// Re-render the app when window.location changes
async function onLocationChange(location, action) {
  // Remember the latest scroll position for the previous location
  scrollPositionsHistory[currentLocation.key] = {
    scrollX: window.pageXOffset,
    scrollY: window.pageYOffset,
  };
  // Delete stored scroll position for next page if any
  if (action === 'PUSH') {
    delete scrollPositionsHistory[location.key];
  }
  currentLocation = location;

  let currentUser;
  async function userDidChange() {
    const previousUser = currentUser;
    currentUser = context.store.getState().user.user;
    if (!_.isEqual(previousUser, currentUser)) {
      let isAuth = false;
      let isAdmin = false;
      let isGlobalAdmin = false;
      if (!_.isEmpty(currentUser)) {
        isAuth = true;
        await saveSessionToServer(currentUser);
        await currentUser
          .get('coordinator')
          .fetchWithInclude(['institution', 'specialty'])
          .then(coordinator => {
            isAdmin = coordinator.get('admin');
            isGlobalAdmin = coordinator.get('globalAdmin');
            context.store.dispatch(setCoordinator(coordinator));
            context.store.dispatch(
              setUserInstitution(coordinator.get('institution')),
            );
            context.store.dispatch(
              setUserSpecialty(coordinator.get('specialty')),
            );
          })
          .catch(() => {
            Parse.User.logOut();
          });
      }
      context.store.dispatch(setAuthProps({ isAuth, isAdmin, isGlobalAdmin }));
    }
  }

  const isInitialRender = !action;
  try {
    context.pathname = location.pathname;
    context.query = queryString.parse(location.search);

    if (isInitialRender) {
      context.store.subscribe(userDidChange);
      const { user } = context.store.getState().user;
      if (!_.isEmpty(user) && location.pathname !== '/logout') {
        const activeUser = await Parse.User.become(user.sessionToken)
          .then(thisUser => {
            // eslint-disable-line no-shadow
            context.store.dispatch(loginUser(thisUser));
            return Promise.resolve(true);
          })
          .catch(error => Promise.resolve(error.code !== 209));
        if (!activeUser) {
          Parse.User.logOut();
          context.store.dispatch(logoutUser());
        }
      }
    }

    // Traverses the list of routes in the order they are defined until
    // it finds the first route that matches provided URL path string
    // and whose action method returns anything other than `undefined`.
    const route = await router.resolve(context);

    // Prevent multiple page renders during the routing process
    if (currentLocation.key !== location.key) {
      return;
    }

    if (route.redirect) {
      history.replace(route.redirect);
      return;
    }

    const component = route.noWrap ? (
      route.component
    ) : (
      <Layout {...{ userAgent }}>{React.cloneElement(route.component)}</Layout>
    );

    const renderReactApp = isInitialRender ? ReactDOM.hydrate : ReactDOM.render;
    appInstance = renderReactApp(
      <Provider store={context.store}>
        <StyleContext.Provider value={{ insertCss }}>
          <App context={context} insertCss={insertCss}>
            {component}
          </App>
        </StyleContext.Provider>
      </Provider>,
      container,
      () => {
        if (isInitialRender) {
          // Switch off the native scroll restoration behavior and handle it manually
          // https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
          if (window.history && 'scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
          }

          const elem = document.getElementById('css');
          if (elem) elem.parentNode.removeChild(elem);
          return;
        }

        document.title = route.title;

        updateMeta('description', route.description);
        // Update necessary tags in <head> at runtime here, ie:
        // updateMeta('keywords', route.keywords);
        // updateCustomMeta('og:url', route.canonicalUrl);
        // updateCustomMeta('og:image', route.imageUrl);
        // updateLink('canonical', route.canonicalUrl);
        // etc.

        let scrollX = 0;
        let scrollY = 0;
        const pos = scrollPositionsHistory[location.key];
        if (pos) {
          scrollX = pos.scrollX;
          scrollY = pos.scrollY;
        } else {
          const targetHash = location.hash.substr(1);
          if (targetHash) {
            const target = document.getElementById(targetHash);
            if (target) {
              scrollY = window.pageYOffset + target.getBoundingClientRect().top;
            }
          }
        }

        // Restore the scroll position if it was saved into the state
        // or scroll to the given #hash anchor
        // or scroll to top of the page
        window.scrollTo(scrollX, scrollY);

        // Google Analytics tracking. Don't send 'pageview' event after
        // the initial rendering, as it was already sent
        if (window.ga) {
          window.ga('send', 'pageview', createPath(location));
        }
      },
    );
  } catch (error) {
    if (__DEV__) {
      throw error;
    }

    console.error(error);

    // Do a full page reload if error occurs during client-side navigation
    if (!isInitialRender && currentLocation.key === location.key) {
      console.error('RSK will reload your page after error');
      window.location.reload();
    }
  }
}

// Handle client-side navigation by using HTML5 History API
// For more information visit https://github.com/mjackson/history#readme
history.listen(onLocationChange);
onLocationChange(currentLocation);

// Enable Hot Module Replacement (HMR)
if (module.hot) {
  module.hot.accept('./router', () => {
    if (appInstance && appInstance.updater.isMounted(appInstance)) {
      // Force-update the whole tree, including components that refuse to update
      deepForceUpdate(appInstance);
    }

    onLocationChange(currentLocation);
  });
}
