import {
  USER,
  COORDINATOR,
  USER_INSTITUTION,
  USER_SPECIALTY,
} from '../constants';

const initialState = {
  user: {},
  coordinator: {},
  institution: {},
  specialty: {},
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case USER:
      return {
        ...state,
        user: action.user,
      };
    case COORDINATOR:
      return {
        ...state,
        coordinator: action.coordinator,
      };
    case USER_INSTITUTION:
      return {
        ...state,
        institution: action.institution,
      };
    case USER_SPECIALTY:
      return {
        ...state,
        specialty: action.specialty,
      };
    default:
      return state;
  }
}
