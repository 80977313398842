import { WORKING_SURVEY_UPDATE, WORKING_SURVEY_CLEAR } from '../constants';

export default function user(state = {}, action) {
  switch (action.type) {
    case WORKING_SURVEY_UPDATE:
      return {
        ...state,
        ...action.survey,
      };
    case WORKING_SURVEY_CLEAR:
      return {
        surveyVersion: -1,
      };
    default:
      return state;
  }
}
