/* eslint-disable global-require */

// The top-level (parent) route
const routes = {
  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '',
      load: () => import(/* webpackChunkName: 'home' */ './home'),
    },
    {
      path: '/login',
      load: () => import(/* webpackChunkName: 'login' */ './login'),
    },
    {
      path: '/logout',
      load: () => import(/* webpackChunkName: 'logout' */ './logout'),
    },
    {
      path: '',
      children: [],
      loadChildren: () =>
        import(/* webpackChunkName: 'changePassword' */ './changePassword'),
    },
    {
      path: '',
      children: [],
      loadChildren: () =>
        import(/* webpackChunkName: 'forgotPassword' */ './forgotPassword'),
    },
    {
      path: '',
      children: [],
      loadChildren: () =>
        import(/* webpackChunkName: 'register' */ './register'),
    },
    {
      path: '/videos',
      children: [],
      loadChildren: () => import(/* webpackChunkName: 'videos' */ './videos'),
    },
    {
      path: '/privacy',
      load: () => import(/* webpackChunkName: 'privacy' */ './privacy'),
    },
    {
      path: '/QRCodes',
      children: [],
      loadChildren: () => import(/* webpackChunkName: 'QRCodes' */ './QRCodes'),
    },
    {
      path: '',
      children: [],
      loadChildren: () => import(/* webpackChunkName: 'survey' */ './survey'),
    },
    {
      path: '/program',
      children: [],
      loadChildren: () => import(/* webpackChunkName: 'program' */ './program'),
    },

    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'notFound' */ './notFound'),
    },
  ],

  async action({ next }) {
    // Execute each child route until one of them return the result
    const route = await next();

    // Provide default values for title, description etc.
    route.title = `${route.title || 'Untitled Page'} - BeFitMe`;
    route.description = route.description || '';

    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./error').default,
  });
}

export default routes;
