/* eslint-disable import/prefer-default-export */

import {
  USER,
  COORDINATOR,
  USER_INSTITUTION,
  USER_SPECIALTY,
} from '../constants';

export function loginUser(user) {
  return {
    type: USER,
    user,
  };
}

export function logoutUser() {
  return dispatch => {
    dispatch({
      type: USER,
      user: {},
    });
    dispatch({
      type: COORDINATOR,
      user: {},
    });
    dispatch({
      type: USER_INSTITUTION,
      user: {},
    });
    dispatch({
      type: USER_SPECIALTY,
      user: {},
    });
  };
}

export function setCoordinator(coordinator) {
  return {
    type: COORDINATOR,
    coordinator,
  };
}

// export function setUserAndAllowedUser({ user, coordinator }) {
//   return dispatch => {
//     dispatch(loginUser(user));
//     dispatch(setCoordinator(coordinator));
//   };
// }

export function setUserInstitution(institution) {
  return {
    type: USER_INSTITUTION,
    institution,
  };
}

export function setUserSpecialty(specialty) {
  return {
    type: USER_SPECIALTY,
    specialty,
  };
}
