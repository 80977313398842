import React from 'react';
import PropTypes from 'prop-types';
// import Parse from 'parse';

// for ensuring this query only runs once for what should be static
const helpEmail = 'help@covenant-data.com';

export default class ContactLink extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      helpEmail,
    };
    this.mounted = false;
  }

  render() {
    const { children, ...props } = this.props;
    const email = `mailto:${this.state.helpEmail}?subject=[BeFitMe]%20Help`;
    return (
      <a {...props} href={email} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }
}
