/* eslint-disable import/prefer-default-export */

export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const SPECIALTIES = 'SPECIALTIES';
export const SELECTED_SPECIALTY = 'SELECTED_SPECIALTY';
export const SELECTED_PROGRAM = 'SELECTED_PROGRAM';
export const SELECTED_CASE = 'SELECTED_CASE';
export const CASE_LIST = 'CASE_LIST';
export const USER = 'USER';
export const COORDINATOR = 'COORDINATOR';
export const USER_INSTITUTION = 'USER_INSTITUTION';
export const USER_SPECIALTY = 'USER_SPECIALTY';
export const IS_AUTH = 'IS_AUTH';
export const IS_ADMIN = 'IS_ADMIN';
export const IS_GLOBAL_ADMIN = 'IS_GLOBAL_ADMIN';
export const REPORT_CASES = 'REPORT_CASES';
export const REPORT_PROCEDURES = 'REPORT_PROCEDURES';
export const REPORT_FETCHED_DATE = 'REPORT_FETCHED_DATE';
export const WORKING_SURVEY_UPDATE = 'WORKING_SURVEY_UPDATE';
export const WORKING_SURVEY_CLEAR = 'WORKING_SURVEY_CLEAR';
