// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3bMN6{height:100px;position:fixed;width:100%;background:#ec4c43;background:-webkit-gradient(linear,left top,right bottom,from(#ec4c43),to(#7f2924));background:-o-linear-gradient(top left,#ec4c43,#7f2924);background:linear-gradient(to bottom right,#ec4c43,#7f2924)}._3k_wl{font-family:Montez,sans-serif!important;font-size:1.5em}._3Vl-0{margin-right:100px}", ""]);
// Exports
exports.locals = {
	"header": "_3bMN6",
	"headerText": "_3k_wl",
	"navItems": "_3Vl-0"
};
module.exports = exports;
