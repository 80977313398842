import { combineReducers } from 'redux';
import auth from './auth';
import runtime from './runtime';
import cases from './case';
import programs from './programs';
import specialty from './specialty';
import user from './user';
import reports from './reports';
import workingSurvey from './workingSurvey';

export default combineReducers({
  auth,
  runtime,
  cases,
  programs,
  specialty,
  user,
  reports,
  workingSurvey,
});
